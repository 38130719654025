import React from "react"
import { withTrans } from "../withTrans"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import MapsComponent from "../components/MapsComponent"

export const query = graphql`
  query($locale: [GRAPHCMS_Locale!]!) {
    gcms {
      contactUsPages(locales: $locale) {
        title1
        description1
        title2
        description2
        title3
        description3
      }
    }
  }
`

class ContactUs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contactUsData: this.props.data.gcms.contactUsPages[0],
    }
  }
  render() {
    const { t } = this.props

    return (
      <Layout>
        <div>
          <div className="font-futurastd_book text-center lg:grid lg:grid-rows-1 lg:justify-evenly lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-center mb-3 mx-3 lg:mx-7">
            <div className="text-center inline-block bg-white">
              <span className="text-2xl">&lt;</span>
              <span className="tracking-title-3 text-base ml-3 mr-2">
                {t("navbar_title_contact_us")}
              </span>
              <span className="text-2xl">&gt;</span>
            </div>
          </div>
          <div className=" mx-7 overflow-hidden grid lg:flex">
            <div className="w-full lg:w-2/4 mr-7">
              <MapsComponent />
            </div>
            <div className="float-right mt-5 lg:mt-0 mr-5 w-full lg:w-2/4">
              <p className="font-futurastd_heavy uppercase">
                {this.state.contactUsData.title1}
              </p>
              <p className="font-futurastd_book text-xs mt-1">
                {this.state.contactUsData.description1}
              </p>
              <p className="font-futurastd_heavy uppercase mt-5">
                {this.state.contactUsData.title2}
              </p>
              <p className="font-futurastd_book text-xs mt-1">
                {this.state.contactUsData.description2}
              </p>
              <p className="font-futurastd_heavy uppercase mt-5">
                {this.state.contactUsData.title3}
              </p>
              <p className="font-futurastd_book text-xs mt-1">
                {this.state.contactUsData.description3}
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default withTrans(ContactUs)
