import React from "react"
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const MyMapComponent = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBaWLBgoRnPiLQ4sVIIfVDJ6OkQqdoVHus&map_ids=2b99ddd30db5bc95",
    loadingElement: <div style={{ height: `100%`  }} />,
    containerElement: <div style={{ height: `400px`, filter: `grayscale(100%) 
    brightness(135%)
    contrast(200%) `}} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)

((props) =>
  <GoogleMap
    defaultZoom={20}
    defaultCenter={{ lat: 30.051355146172394, lng: 31.24356722877813 }}
  >
    {props.isMarkerShown && <Marker position={{ lat: 30.051355146172394, lng: 31.24356722877813 }} onClick={props.onMarkerClick} />}
  </GoogleMap>
)

class MapsComponent extends React.PureComponent {
  state = {
    isMarkerShown: false,
  }

  componentDidMount() {
    this.delayedShowMarker()
  }

  delayedShowMarker = () => {
    setTimeout(() => {
      this.setState({ isMarkerShown: true })
    }, 3000)
  }

  handleMarkerClick = () => {
    this.setState({ isMarkerShown: false })
    this.delayedShowMarker()
  }

  render() {
    return (
      <MyMapComponent
        isMarkerShown={this.state.isMarkerShown}
        onMarkerClick={this.handleMarkerClick}
      />
    )
  }
}

export default MapsComponent
// bootstrapURLKeys={{ key: "AIzaSyDY8l6057uQ7M5ehlklw0OPlgrtRHj_kas" }}
// 30.051355146172394, 31.24356722877813
